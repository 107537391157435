import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Tags from "../components/tags"
import { Link, graphql } from 'gatsby'
import _ from "lodash"

export default function Template({
  data,
}) {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <Helmet title={`Jessa Sparks | ${post.frontmatter.title}`} />
        <div className="row">
          <div className="col-sm-10">
            <div className="card my-3">
              <div className="card-body">
                <h3 className="card-title">
                    <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
                </h3>
                <p><small>{post.frontmatter.date}</small></p>
                <div dangerouslySetInnerHTML={{ __html: post.html }}></div>

                <p>Tags: {post.frontmatter.tags.map((tag, index) => {
                    return (
                        <span key={index}>
                            <Link className="tag" to={`/tags/${_.kebabCase(tag)}/`}> {tag}</Link>
                            { index !== post.frontmatter.tags.length - 1 ? ',' : '' }
                        </span>
                    )
                  })
                }</p>                               
              </div>
            </div>
          </div>
          <div className="col-sm-2">
              <Tags/>
          </div>     
        </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        tags
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`