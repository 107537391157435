import React from "react"
import kebabCase from "lodash/kebabCase"
import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"

const Tags = ({ 
  data: {
    allMarkdownRemark: { group }
  }
}) => (
  <div>
    <div>
      <h4>Tags</h4>
      {group.map(tag => (
        <p key={tag.fieldValue}>
          <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
            {tag.fieldValue} <span className="badge badge-pill badge-tertiary">{tag.totalCount}</span>
          </Link>
        </p>
      ))}
    </div>
  </div>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={data => <Tags data={data} {...props} />}
  />
)

Tags.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(limit: 2000) {
//       group(field: frontmatter___tags) {
//         fieldValue
//         totalCount
//       }
//     }
//   }
// `